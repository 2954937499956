<template>
      <div class="coverPage A4Container flexCenter pageBreak_no">
        <div class="flexCloumnBetween" style="width: 80%;height: 80%;">

        <div>
          <div class="subTitle">{{(stuInfo.showTopSchoolName&&stuInfo.xuerxiao)||'南京市中等职业学校'}}</div>
          <div class="title" style="font-weight:700;">学&nbsp;生&nbsp;实&nbsp;习&nbsp;手&nbsp;册</div>
        </div>
        <div class="form">
          <div class="formItem flexStart">
            <div class="label">学校</div>
            <div class="input">{{ stuInfo.xuerxiao ||'-' }}</div>
          </div>
          <div class="formItem flexStart">
            <div class="label">班级</div>
            <div class="input">{{ stuInfo.class_name||'-' }}</div>
          </div>
          <div class="formItem flexStart">
            <div class="label">姓名</div>
            <div class="input">{{ stuInfo.stuName || '-'  }}</div>
          </div>
          <div class="formItem flexStart">
            <div class="label">专业</div>
            <div class="input">{{ stuInfo.professionalName||'-'}}</div>
          </div>
          <div class="formItem flexStart">
            <div class="label">指导教师</div>
            <div class="input">{{ stuInfo.mentorName||'-' }}</div>
          </div>
          <div v-if="stuInfo.pmid!=229" class="formItem flexStart">
            <div class="label">实习企业</div>
            <div class="input">{{ stuInfo.firm_name || '-' }}</div>
          </div>
          <div class="formItem flexStart">
            <div class="label">实习时间</div>
            <div class="input">{{ stuInfo.startTime }} 至 {{ stuInfo.endTime }}</div>
          </div>
        </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'cover',
  props: {
    stuInfo: {
      type: Object,
      default: {}
    }
  }
}
</script>
