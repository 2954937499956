<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id="printMe" style=" width: 21cm; margin: 0 auto;border: 0;">
      <div style="width:100%;" v-for="(item, index) in dataList" :key="index">


        <div class="stuContact stuBest A4Container flexCloumnCenter">
          <div class="title">优秀实习生事迹记录</div>
          <div class="form">
            <div class="flexCloumnSingleCenter" style="width: 100%;height: 100%;">
              <el-row class="el-row" style="line-height: 40px;padding: 0 10px">
                <el-col :span="8" class="flexStart">学生姓名：{{item.stu_name}}</el-col>
                <el-col :span="16" class="flexStart">实习单位：{{item.enter_name}}</el-col>
              </el-row>
              <div class="flexStart" style="width:100%;height:calc( 100% - 40px );position: relative">
                <div style="height: 100%;border-right: 1px solid #000;padding: 0 20px;
                        display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                  <div>事</div>
                  <div>迹</div>
                  <div>简</div>
                  <div>介</div>
                </div>
                <div id="divCntnrA" style="width:100%;height:100%;">
                  <!-- <div
                    :class="(item.annex && item.annex.length > 0) ? 'textIndent2 heightDiv2' : 'textIndent2 heightDiv1'"
                    style="white-space: pre-wrap;">
                    <div id="resizeDivA" class="textIndent2" style="white-space: pre-wrap;">
                      {{ item.event_desc }}
                    </div>
                  </div> -->
                  <div class="textIndent2 heightDiv1" style="white-space: pre-wrap;">
                    <div id="resizeDivA" class="textIndent2" style="white-space: pre-wrap;">
                      {{ item.event_desc }}
                    </div>
                  </div>
                  <div id="annexDivA" style="width: 100%;height: 200px;">
                    <div v-if="caseStation>1&&item.annex && item.annex.length>0"
                      style="width: 100%;height: 100%;overflow: hidden;padding-bottom:0px;overflow: hidden;">
                      <div class="flexStart" style="width:100%;height:100%;flex-wrap:wrap;">
                        <div v-for="(itemss, indexss) in item.annex" :key="indexss"
                          style="width: 48%;height: calc( 100% - 10px );margin: 0 1%;overflow: hidden;">
                          <img :src="itemss.src ||itemss.fileUrl" alt=""
                            style="width: 100%;height: 100%;object-fit: contain;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="flexStart" style="position: absolute;bottom: 10px;right: 10px;">
                  <div>班主任（签名）：</div>
                  <img v-if="item.handsign" :src="item.handsign" alt="" style="width: 100px;max-height: 100px;">
                  <div v-else style="width: 100px;"></div>
                </div>
              </div>
            </div>

          </div>
        </div>




        <div v-if="caseStation == 1 && item.annex && item.annex.length > 0" class="stuContact stuBest A4Container flexCloumnCenter">
          <div class="title">优秀实习生事迹记录</div>
          <div class="form">
            <div class="flexCloumnSingleCenter" style="width: 100%;height: 100%;">
              <div class="flexStart" style="width:100%;height:calc( 100% - 0px );position: relative">
                <div style="height: 100%;border-right: 1px solid #000;padding: 0 20px;
                        display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                  <div>事</div>
                  <div>迹</div>
                  <div>简</div>
                  <div>介</div>
                </div>
                <div id="divCntnrB" style="width:100%;height:100%;">

                  <div class=""
                    style="width: 100%;min-height: 200px;overflow: hidden;padding-bottom:0px;overflow: hidden;">
                    <div class="flexStart" style="width:100%;height:100%;flex-wrap:wrap;">
                      <div v-for="(itemss, indexss) in item.annex" :key="indexss"
                        style="width: 500px;height: 400px;margin: 0 1%;overflow: hidden;">
                        <img :src="itemss.src || itemss.fileUrl" alt=""
                          style="width: 100%;height: 100%;object-fit: contain;margin-bottom:10px;">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>






    </div>

    <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">打印
    </el-button>
  </div>
</template>

<script>
import Stubest from "./com_tch/stuBest.vue";

export default {
  name: "export",
  components: {
    Stubest,
  },
  data() {
    return {
      pdfok: false,
      show_dev: true,
      show_dev1: true,
      printObj: {
        id: "printMe",
        popTitle: "",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: "",
      cover: {},
      rolls: [],
      stu_kaohe: [],
      stu_lianxi: [],
      shixi_anli: [{}],
      youxiu_shixisheng: [],
      stu_weiji: [],
      fanxiao: [],
      dataInfo: {},
      dataList: [],
      caseStation:0,
    };
  },
  mounted() {
    let userid = this.getParams("id");
    let pmid = this.getParams("pmid");
    let reportMonth = this.getParams('report_month')
    this.getAllPickerVal(pmid).then(rr => {
      this.getDataList(userid, pmid,reportMonth, rr);
    });
  },
  methods: {
  
    getAllPickerVal(pmid) {
      return new Promise(resolve => {
        let arrForm = [],
          arrState = [];
        //巡访形式&&巡访情况
        this.$http
          .post("/api/sys_param_list", {
            param_type: "'VISIT_FORM','VISIT_SITUATION'",
            pmid: parseInt(pmid)
          })
          .then(res => {
            res.data &&
              res.data.map((item, index, arr) => {
                if (item.param_type == "VISIT_FORM") {
                  arrForm.push(item);
                }
                if (item.param_type == "VISIT_SITUATION") {
                  arrState.push(item);
                }
              });
            resolve({
              arrForm,
              arrState
            });
          });
      });
    },

    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    stripscript(s) {
      var mdash = "&mdash;";
      var nbsp = "&nbsp;";
      var quot = "&quot;";
      var ldquo = "&ldquo;";
      var rdquo = "&rdquo;";
      var amp = "&amp;";
      var lt = "&lt;";
      var gt = "&gt;";

      var rs = "";
      rs = s.replace(new RegExp(mdash, "g"), "-");
      rs = rs.replace(new RegExp(nbsp, "g"), "");
      rs = rs.replace(new RegExp(quot, "g"), '"');
      rs = rs.replace(new RegExp(ldquo, "g"), "”");
      rs = rs.replace(new RegExp(rdquo, "g"), "“");
      rs = rs.replace(new RegExp(amp, "g"), "&");
      rs = rs.replace(new RegExp(lt, "g"), "<");
      rs = rs.replace(new RegExp(gt, "g"), ">");
      return rs;
    },
    getDataList(userid, pmid, reportMonth, rr) {
      let _this = this;
      let arrForm = rr ? rr.arrForm : [];
      this.$http
        .post("/api/getStuEventData", {
          class_id: parseInt(userid),
          pmid: parseInt(pmid),
          report_month: reportMonth,
        })
        .then(res => {
          if (res.data) {
            res.data.map(a => {
              if (a.event_desc) {
                const text = this.HTMLPartToTextPart(a.event_desc);
                a.event_desc = text
              }
              if (a.annex) {
                a.annex = JSON.parse(a.annex)
                a.annex.map(f => {
                  if (f.fileUrl && f.fileUrl.indexOf('https')>-1) {
                    f.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                  }
                  if (f.src && f.src.indexOf('https') > -1) {
                    f.src += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                  }
                  console.log(f.fileUrl, f.src)
                })
              }
            })
           
            this.dataList = res.data;
          }
          this.$nextTick(() => {
            _this.resizeA()
            setTimeout(() => {
              _this.pdfok = true;
            }, 500);
          });
        });
    },
    HTMLPartToTextPart(HTMLPart) {
      let aa = HTMLPart
        // .replace(/\n/ig, '')
        .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, '')
        .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, '')
        .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, '')
        .replace(/<\/\s*(?:p|div)>/ig, '\n')
        .replace(/<br[^>]*\/?>/ig, '\n')
        .replace(/<[^>]*>/ig, '')
        .replace('&nbsp;', " ")
        .replace(/[^\S\r\n][^\S\r\n]+/ig, ' ')
      var mdash = "&nbsp;";
      aa = aa.replace(new RegExp(mdash, "g"), " ")
      return aa
    },
    getParams(name) {
      let n_url = window.location.href;
      n_url = n_url.replace("/#", "");
      if (n_url.indexOf("?") > 0) {
        n_url = n_url.split("?")[1];
      }
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = n_url.match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = [];
      while (arr.length) {
        _arr.push(arr.splice(0, num));
      }
      return _arr;
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {};
      array.forEach(function (o) {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    resizeA() {
      let id1 = 'divCntnrA'
      let id2 = 'resizeDivA'
      let id3 = 'annexDivA'

      let outHeight = document.getElementById(id1).offsetHeight
      let innerHeight = document.getElementById(id2).offsetHeight
      let diffH = outHeight - innerHeight
      console.log('a', outHeight, innerHeight, diffH)
      let caseStation = 0
      if (diffH<200) {
        caseStation = 1
      }
      if (diffH >= 200 && diffH<300) {
        caseStation = 2
      }
     
      if (diffH >= 300 && diffH < 400) {
        caseStation = 3
      }

      if (diffH >= 400 && diffH < 500) {
        caseStation = 4
      }
      if (diffH >= 500 ) {
        caseStation = 5
      }
      this.caseStation = caseStation
      if (caseStation > 1) {
        var elementA = document.getElementById(id3);
        // console.log(caseStation,elementA)
        elementA.style.height = `${caseStation}00px`; 
      }

      // let i = 1
      // while (textHeight > divHeight && i < 7) {
      //   document.getElementById(id2).style.fontSize = `${18 - i}px`
      //   textHeight = document.getElementById(id2).offsetHeight
      //   i++

      // }
    },
  }
};
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
.stuContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(29.7cm - 100px);
    border: 1px solid #000;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    /*.flexCloumnSingleCenter:first-of-type {
      border-bottom: 1px solid #000 !important;
    }*/

    .content {
      width: 100%;
      height: calc(100% - 40px);
    }

    .el-row {
      //border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

    .heightDiv1 {
      max-height: 100%;
      padding: 0 10px;
      flex: 1;
      position: relative;
      overflow: hidden;
    }
  
    .heightDiv2 {
      max-height: calc(100% - 200px);
      padding: 0 10px;
      flex: 1;
      position: relative;
      overflow: hidden;
    }
}
</style>
