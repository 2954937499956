<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div v-if="dataover222" id="printMe" style=" width: 21cm; margin: 0 auto;">
      <Cover v-if="show_dev" :stuInfo="stuInfo"></Cover>
      <div v-if="show_dev" class="A4Container"></div>
      <CoverNext v-if="show_dev"></CoverNext>
      <div v-if="show_dev" class="A4Container"></div>
      <Rules v-if="show_dev"></Rules>


      <div v-if="show_dev" class="A4Container"></div>
      <PerInfo v-if="show_dev" :stuinfo="stuInfo" :pmid="pmid"></PerInfo>

      <div v-if="show_dev && yuebao && yuebao.length > 0" v-for="(item, index) in yuebao" :key="index">
        <Yuebao :info1="item" :idx="index" :stuinfo="stuInfo" :pmid="pmid"></Yuebao>
        <YuebaoKPI :info="item"></YuebaoKPI>
      </div>
      <div v-if="show_dev1" v-for="(item, index) in zhoubao" :key="index + 'a'">
        <Zhoubao :info1="item" :idx="index" :stuinfo="stuInfo"></Zhoubao>
      </div>
      <Identify v-if="showIdentify" :stuinfo="stuInfo"></Identify>
      <IdentifyTeacher v-if="showIdentify" :stuinfo="stuInfo"></IdentifyTeacher>
    </div>




    <!--    <el-button v-print="'#printMe'" type="primary" style="position: fixed;top: 60px;right: 10px;z-index: 999;">-->
    <!--      打印-->
    <!--    </el-button>-->

  </div>
</template>

<script>
import Cover from './com_stu/cover'
import CoverNext from './com_stu/cover_next'
import Rules from "./com_stu/rules";

import PerInfo from './com_stu/perInfo'
import Yuebao from './com_stu/yuebao'
import YuebaoKPI from './com_stu/yuebao_kpi'
import Zhoubao from './com_stu/zhoubao'
import Identify from "./com_stu/identify"
import IdentifyTeacher from "./com_stu/identify_teacher"

export default {
  name: 'export',
  components: {
    Cover, CoverNext, Rules,
    PerInfo, Yuebao, YuebaoKPI, Zhoubao, Identify, IdentifyTeacher
  },
  data() {
    return {
      pdfok: false,
      show_dev: true,
      show_dev1: true,
      dataover222: true,
      showIdentify: false,
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: '',

      stuInfo: {},
      yuebao: [{}],
      zhoubao: [{}],
      chinaCitys: [],
      // 填入导出的pdf文件名和html元素
      pdfTitle: '试卷1',
      pdfSelector: '#printMe',
      pmid:'',
    }
  },
  mounted() {
    let id = this.getParams('id')
    let pmid = this.getParams('pmid')
    // let id = 'fd05786013204a96ae7586c9cd77a32a'
    this.pmid = pmid
    console.log(1111111, id, pmid)
    this.getChinaCity().then(rr => {
    })
    this.getDataList(id, pmid)

  },
  methods: {
    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    stripscript(s) {
      var pattern = new RegExp('[&mdash;]')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '-')
      }
      return rs
    },

    getChinaCity() {
      return new Promise(resolve => {
        this.$http.post("/api/china_citys").then(res => {
          this.chinaCitys = res.data
          resolve()
        })
      })
    },
    findDeep(list, iid) {
      for (let i in list) {
        if (list[i].value === iid) {
          return [list[i]]
        }
        if (list[i].children != null) {
          let node = this.findDeep(list[i].children, iid);
          if (node !== undefined) {
            return node.concat(list[i])
          }
        }
      }

    },
    getDataList(stu_id, pmid, status) {
      let _this = this
      if (pmid == 210) {
        this.showIdentify = true
      }
      this.$http.post('/api/initstudatapdf', {
        stu_id: parseInt(stu_id) ? parseInt(stu_id) : stu_id,
        pmid: parseInt(pmid)
      }).then(res => {
        if (res.data) {
          let rData = res.data
          if (res.data.stuInfo && res.data.stuInfo.jiguan) {
            let arr = this.findDeep(this.chinaCitys, res.data.stuInfo.jiguan)
            let arr1 = this._lo.reverse(arr)
            let str = this._lo.map(arr1, function (n) {
              return n.label
            }).join(',')
            res.data.stuInfo.jiguan = str || res.data.stuInfo.jiguan
          }
          if (res.data.stuInfo && res.data.stuInfo.skill_img && this.isJSON(res.data.stuInfo.skill_img)) {
            res.data.stuInfo.skill_img = JSON.parse(res.data.stuInfo.skill_img)
          }
          if (res.data.stuInfo && res.data.stuInfo.skill_json && this.isJSON(res.data.stuInfo.skill_json)) {
            res.data.stuInfo.skill_json = JSON.parse(res.data.stuInfo.skill_json)
          }
          let stuInfo = rData.stuInfo ? rData.stuInfo : {}
          let bzrInfo = rData.bzrInfo ? rData.bzrInfo : {}
          let enterprise = rData.enterprise ? rData.enterprise : {}
          let showTopSchoolName = 1
          if (pmid == 229) {//中华中专不需要显示封面顶部学校
            showTopSchoolName = 0
          }
          this.stuInfo = Object.assign({},
            { ...stuInfo },
            { ...bzrInfo },
            { ...enterprise },
            {
              pmid: pmid,
              showTopSchoolName: showTopSchoolName,
              xuerxiao: rData.xuerxiao,
              mentorName: rData.mentorName,
              nationName: rData.nationName,
              politicalStatusName: rData.politicalStatusName,
              professionalName: rData.professionalName,
              sexName: rData.sexName,
              startTime: rData.startTime,
              endTime: rData.endTime,
              createDate: rData.createDate,
            })

          if (rData.yuebao) {
            if (pmid==215) {//南京财经不需要打卡，所以矿工显示为0
              rData.yuebao.map(a => {
                a.kg = 0
              })
            }
            this.yuebao = rData.yuebao
          }
          if (rData.zhoubao) {
            this.zhoubao = rData.zhoubao
          }
        }

        this.$nextTick(() => {
          setTimeout(() => { _this.pdfok = true }, 500)
        })

      })

    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
