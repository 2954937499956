<template>
    <div>
        <div class="stuContact A4Container flexCloumnCenter pageBreak_no">
            <div class="title">{{ info.report_month }}月份实习情况考评表</div>
            <div class="form">
                <div class="flexCloumnSingleCenter" style="width: 100%;height: 100%;">
                    <el-row class="el-row1" style="width: 100%;padding: 0 10px;height:40px;">
                        <el-col :span="12" class="flexStart" style="height:100%;">
                            <div class="flexCenter">实习单位：</div>
                            <div class="flex_1 overMoreLine"
                                style="white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;">
                                {{stuinfo.firm_name||'-'}}
                            </div>
                        </el-col>
                        <el-col :span="6" class="flexStart" style="height:100%;">
                            <div class="flexCenter">实习岗位：</div>
                            <div class="flex_1 overMoreLine"
                                style="white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;">
                                {{ info.job || '-' }}
                            </div>
                        </el-col>
                        <el-col :span="6" class="flexStart" style="height:100%;">
                            <div class="flexCenter">变更岗位：</div>
                            <div class="flex_1 overMoreLine"
                                style="white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;">
                                无</div>
                        </el-col>
                    </el-row>
                    <div class="desc el-row1" style="border-bottom: 1px solid #000;width: 100%;">
                        <el-row class="" style="line-height: 30px;padding: 0 10px;">出勤情况:</el-row>
                        <el-row class="" style="line-height: 30px;padding: 0 10px;">
                            <el-col :span="8" class="flexBetween">
                                <div>应到工作日：</div>
                                <div class="flex_1 flexAround">
                                    <div>{{ info.wDays || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexBetween">
                                <div>实际工作日：</div>
                                <div class="flex_1 flexAround">
                                    <div>{{ info.realDays || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexBetween">
                                <div>返校：</div>
                                <div class="flex_1 flexAround">
                                    <div>{{ info.rDays || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row class="" style="line-height: 30px;padding: 0 10px;">
                            <el-col :span="pmid ==229?5:6" class="flexBetween">
                                <div>事假：</div>
                                <div class="flex_1 flexAround">
                                    <div>{{ info.sj || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                            <el-col :span="pmid == 229 ? 5 : 6" class="flexBetween">
                                <div>病假：</div>
                                <div class="flex_1 flexAround">
                                    <div>{{ info.bj || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                            <el-col v-if="pmid==229" :span="pmid == 229 ? 4 : 6" class="flexBetween">
                                <div>迟到：</div>
                                <div class="flex_1 flexAround">
                                    <div> {{ info.cd || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                            <el-col :span="pmid == 229 ? 5 : 6" class="flexBetween">
                                <div>早退：</div>
                                <div class="flex_1 flexAround">
                                    <div> {{ info.zt || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                            <el-col :span="pmid == 229 ? 5 : 6" class="flexBetween">
                                <div>旷工：</div>
                                <div class="flex_1 flexAround">
                                    <div>{{ info.kg || 0 }}</div>
                                    <div>天；</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div style="width: 100%;height: calc( 100% - 132px );">
                        <div class="content"
                            style="width:100%;height: 75%;overflow: hidden;box-sizing: border-box;position: relative;">
                            <div :id="'divCntnrA_'+idx" :style="(info.att_coutent&&info.att_coutent.length>0)?
                                 'width:100%;height: calc( 100% - 160px );overflow: hidden;':
                                 'width:100%;height: 100%;overflow: hidden;'">
                                <div :id="'resizeDivA_'+idx" style="width: 100%;word-break: break-all">
                                    <div v-for="(items,indexs) in info.report_info">
                                        <div v-if="items.type!='file'"
                                            :class="[(items.type=='checkbox'||items.type=='radio'||items.type=='select')?'flexStart':'flexCloumn']">
                                            <div class="content_title" style="font-weight: 700;width: unset;">
                                                {{items.name}}：</div>
                                            <div class="content_real_values flex_1"
                                                style="width: unset;white-space: normal;">{{items.real_values||'-'}}
                                            </div>
                                        </div>
                                        <div v-if="items.type=='file'&&items.real_values&&items.real_values.length>0"
                                            class="" style="width: 100%;height: 150px;overflow: hidden;">
                                            <div class="content_title"
                                                style="font-weight: 700;width: unset;height: 30px;">{{items.name}}：
                                            </div>
                                            <div v-for="(itemss,indexss) in items.real_values" :key="indexss"
                                                style="width: 24%;height: calc( 100% - 30px );margin-right: 1%;">
                                                <img :src="itemss.fileUrl" alt=""
                                                    style="max-width: 100%;max-height: 100%;">
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="flexStart"
                                style="width: 100%;height: 150px;overflow: hidden;position: absolute;bottom: 10px;">
                                <div v-for="(item,index) in info.att_coutent" :key="index"
                                    style="width: 24%;height: 100%;margin-right: 1%;">
                                    <img :src="item.fileUrl" alt="" style="max-width: 100%;max-height: 100%;">
                                </div>

                            </div>
                        </div>
                        <div class="commit el-row1" style="position: relative;width: 100%;height: 25%;padding: 10px;">
                            <div style="width: 100%;height:100%;word-break: break-all">
                                <div :id="'divCntnrB_'+idx"
                                    style="width: 100%;height: calc( 100% - 70px );overflow: hidden">
                                    <div :id="'resizeDivB_'+idx" style="width: 100%;word-break: break-all">
                                        评语：{{ info.review_comment }}
                                    </div>
                                </div>
                                <div style="position: absolute;bottom: 10px;right: 10px;line-height: unset!important;">
                                    <div class="flexStart" style="line-height: unset!important;">
                                        {{ info.review_time }}
                                    </div>
                                    <div class="flexStart" style="line-height: unset!important;">
                                        <div>班主任(带队教师) 签字：</div>
                                        <img v-if="info.sign_img" :src="info.sign_img" alt=""
                                            style="max-width: 100px;max-height: 50px;">
                                        <div v-else style="width: 100px;"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'yuebao',
    data () {
        return {
            dataover: false,
            userid: '',
            info: {}
        }
    },
    props: {
        info1: {
            type: Object,
            default: {}
        },
        idx: {
            type: Number,
            default: 1
        },
        stuinfo: {
            type: Object,
            default: {}
        },
        pmid: {
            type: String,
            default: '0'
        },
    },
    mounted () {
        this.init()
    },
    methods: {
        init() {
            let _this = this
            let info = JSON.parse(JSON.stringify(_this.info1))
            if (info.att_coutent) {
                info.att_coutent = JSON.parse(info.att_coutent)
                info.att_coutent.map(a => {
                    a.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                    console.log(a.fileUrl)
                })
            } else {
                info.att_coutent = []
            }
            _this.info = { ...info }

            if (info.report_info) {
                let index = info.report_info.indexOf('应到工作日')
                let index1 = info.report_info.indexOf('实际工作日')
                let index2 = info.report_info.indexOf('返校天数')
                let flag = 0
                if (index > -1 && index1 > -1 && index2>-1) {
                    flag=1
                }
                if (flag && this.isJSON(info.report_info)) {
                    console.log('flag==1')
                    let arr0 = JSON.parse(info.report_info)
                    let arr1 = JSON.parse(JSON.stringify(arr0))
                    let initArr = JSON.parse(JSON.stringify(arr0))
                    if (arr0 && arr0.length>0) {
                        arr0 = arr1.splice(8)
                        info.wDays = initArr[0].real_values
                        info.realDays = initArr[1].real_values
                        info.rDays = initArr[2].real_values
                        info.sj = initArr[3].real_values
                        info.bj = initArr[4].real_values
                        info.cd = initArr[5].real_values
                        info.zt = initArr[6].real_values
                        info.kg = initArr[7].real_values
                    }
                    info.report_info = arr0
                    info.report_info.map(a => {
                        if (a.type == 'file' && a.real_values && a.real_values.length > 0) {
                            a.real_values.map(f => {
                                f.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                                console.log(f.fileUrl)
                            })
                        }
                    })  
                    
                }
                if (!flag && this.isJSON(info.report_info)) {
                    console.log('flag==0')
                    info.report_info = JSON.parse(info.report_info)
                    info.report_info.map(a => {
                        if (a.type == 'file' && a.real_values && a.real_values.length > 0) {
                            a.real_values.map(f => {
                                f.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                                console.log(f.fileUrl)
                            })
                        }
                    })      
                }




                // info.report_info = _this.stripscript(info.report_info)
                _this.info = { ...info }
                _this.$nextTick(() => {
                    _this.resizeA()
                    _this.resizeB()
                })


            } else {
                setTimeout(function () {
                    _this.init()
                }, 500)
            }
        },
        stripscript (s) {
            var pattern = new RegExp('\n')
            var rs = ''
            for (var i = 0; i < s.length; i++) {
                rs = rs + s.substr(i, 1).replace(pattern, '')
            }
            return rs
        },
        resizeA () {
            let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
            let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
            console.log('a', divHeight, textHeight)
            let i = 1
            while (textHeight > divHeight && i < 5) {
                document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
                textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
                i++
            }
        },
        resizeB () {
            let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
            let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
            console.log('b', this.idx,divHeight, textHeight)
            let i = 1
            while ((textHeight > divHeight) && i < 5) {
                document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
                textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
                i++
            }
        }
    }
}
</script>

<style lang="less" type="text/less">
.stuContact .ant-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuContact .ant-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>

.stuContact {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;
        border-top: 0;
        overflow: hidden;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }

        .el-row1 {
            border-top: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
