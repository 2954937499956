<template>
    <div>
        <div class="stuContact stuBest A4Container flexCloumnCenter pageBreak_no" v-for="(item, index) in info"
            :key="index">
            <div class="title">优秀实习生事迹记录</div>
            <div class="form">
                <div class="flexCloumnSingleCenter" v-for="(items,indexs) in item" style="width: 100%;height: 50%;">
                    <el-row class="el-row" style="line-height: 40px;padding: 0 10px">
                        <el-col :span="8" class="flexStart">学生姓名：{{items.stu_name}}</el-col>
                        <el-col :span="16" class="flexStart">实习单位：{{items.firm_name}}</el-col>
                    </el-row>
                    <div class="content flexStart" style="overflow: hidden;">
                        <div style="height: 100%;border-right: 1px solid #000;padding: 0 20px;
                        display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                            <div>事</div>
                            <div>迹</div>
                            <div>简</div>
                            <div>介</div>
                        </div>
                        <div class="flex_1" style="height:100%;position: relative;">
                            <div class="textIndent2" v-html="items.event_desc"
                                style="padding: 10px; flex: 1;height:100%;position: relative;">1212
                            </div>
                            <div class="flexStart"
                                style="position: absolute;bottom: 10px;right: 10px;padding-left:6px;background-color: #fff;">
                                <div>班主任（签名）：</div>
                                <img v-if="items.sign_img" :src="items.sign_img" alt=""
                                    style="width: 100px;max-height: 100px;">
                                <div v-else style="width: 100px;"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'contact',
    data () {
        return {
            dataover: false,
            userid: ''
        }
    },
    props: {
        info: {
            type: Array,
            default: []
        }
    },
    mounted () {
    },
    methods: {}
}
</script>

<style lang="less" type="text/less">
.stuContact .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuContact .el-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
.stuBest {
    p {
        margin: 0;
    }
}
</style>
<style lang="less" type="text/less" scoped>

.stuContact {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .flexCloumnSingleCenter:first-of-type {
            border-bottom: 1px solid #000 !important;
        }

        .content {
            width: 100%;
            height: 100%;
        }

        .el-row {
            //border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
